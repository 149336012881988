var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "LeaveTimeUnit" },
    [
      _c(
        "a-form-model-item",
        { attrs: { label: "请假时间单位", prop: "vacationUnit" } },
        [
          _c("DictionariesInput", {
            attrs: {
              parameter: "vacation_unit",
              placeholder: "请选择请假时间单位",
            },
            on: { change: _vm.unitChange },
            model: {
              value: _vm.form.vacationUnit,
              callback: function ($$v) {
                _vm.$set(_vm.form, "vacationUnit", $$v)
              },
              expression: "form.vacationUnit",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: ["D", "H"].includes(_vm.form.vacationUnit),
              expression: "['D','H'].includes(form.vacationUnit)",
            },
          ],
        },
        [
          _c(
            "a-form-model-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.form.vacationUnit === "H",
                  expression: "form.vacationUnit ==='H'",
                },
              ],
              attrs: { label: "请假时长计算", prop: "vacationCalcType" },
            },
            [
              _c("DictionariesInput", {
                attrs: {
                  parameter: "vacation_calc_type",
                  placeholder: "请选择请假时长计算方式",
                },
                model: {
                  value: _vm.form.vacationCalcType,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "vacationCalcType", $$v)
                  },
                  expression: "form.vacationCalcType",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              key: "time",
              attrs: { label: "单次请假限制", prop: "limitFlag" },
            },
            [
              _c(
                "a-radio-group",
                {
                  on: { change: _vm.timesChange },
                  model: {
                    value: _vm.form.limitFlag,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "limitFlag", $$v)
                    },
                    expression: "form.limitFlag",
                  },
                },
                [
                  _c(
                    "a-radio",
                    { style: _vm.inlineRadioStyle, attrs: { value: false } },
                    [_vm._v("不限制")]
                  ),
                  _c(
                    "a-radio",
                    { style: _vm.inlineRadioStyle, attrs: { value: true } },
                    [_vm._v("限制")]
                  ),
                  _vm.form.limitFlag
                    ? _c(
                        "div",
                        { staticClass: "flex-align-center" },
                        [
                          _c("CbNumber", {
                            staticClass: "number-input margin-left-none",
                            attrs: {
                              "is-zero": false,
                              "decimal-separator": true,
                              suffix:
                                _vm.form.vacationUnit === "D" ? "天" : "小时",
                            },
                            model: {
                              value: _vm.form.limitMin,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "limitMin", $$v)
                              },
                              expression: "form.limitMin",
                            },
                          }),
                          _c("span", { domProps: { innerHTML: _vm._s("≤") } }),
                          _c("span", { staticClass: "time-text" }, [
                            _vm._v("单次请假时长"),
                          ]),
                          _vm._v("≤ "),
                          _c("CbNumber", {
                            staticClass: "number-input margin-right-none",
                            attrs: {
                              "is-zero": false,
                              "decimal-separator": true,
                              suffix:
                                _vm.form.vacationUnit === "D" ? "天" : "小时",
                            },
                            model: {
                              value: _vm.form.limitMax,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "limitMax", $$v)
                              },
                              expression: "form.limitMax",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          !["CHJ", "HUJ", "SAJ"].includes(_vm.form.vacationTypeCode) &&
          _vm.form.vacationUnit === "D"
            ? _c(
                "a-form-model-item",
                {
                  key: "month",
                  attrs: { label: "单月请假限制", prop: "monthLimitFlag" },
                },
                [
                  _c(
                    "a-radio-group",
                    {
                      on: { change: _vm.monthsChange },
                      model: {
                        value: _vm.form.monthLimitFlag,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "monthLimitFlag", $$v)
                        },
                        expression: "form.monthLimitFlag",
                      },
                    },
                    [
                      _c(
                        "a-radio",
                        {
                          style: _vm.inlineRadioStyle,
                          attrs: { value: false },
                        },
                        [_vm._v("不限制")]
                      ),
                      _c(
                        "a-radio",
                        { style: _vm.inlineRadioStyle, attrs: { value: true } },
                        [_vm._v("限制")]
                      ),
                      _vm.form.monthLimitFlag
                        ? _c(
                            "div",
                            { staticClass: "flex-align-center" },
                            [
                              _c("CbNumber", {
                                staticClass: "number-input margin-left-none",
                                attrs: {
                                  disabled: "",
                                  suffix:
                                    _vm.form.vacationUnit === "D"
                                      ? "天"
                                      : "小时",
                                  "decimal-separator": true,
                                },
                                model: {
                                  value: _vm.form.monthLimitMin,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "monthLimitMin", $$v)
                                  },
                                  expression: "form.monthLimitMin",
                                },
                              }),
                              _c("span", {
                                domProps: { innerHTML: _vm._s("≤") },
                              }),
                              _c("span", { staticClass: "time-text" }, [
                                _vm._v("单月请假时长"),
                              ]),
                              _vm._v("≤ "),
                              _c("CbNumber", {
                                staticClass: "number-input margin-right-none",
                                attrs: {
                                  min: _vm.form.limitMax,
                                  "is-zero": false,
                                  suffix:
                                    _vm.form.vacationUnit === "D"
                                      ? "天"
                                      : "小时",
                                  "decimal-separator": true,
                                },
                                model: {
                                  value: _vm.form.monthLimitMax,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "monthLimitMax", $$v)
                                  },
                                  expression: "form.monthLimitMax",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.form.vacationUnit === "B",
              expression: "form.vacationUnit ==='B'",
            },
          ],
        },
        [
          _c(
            "a-form-model-item",
            {
              key: "B",
              attrs: { label: "半天折算标准", prop: "halfDayCalcType" },
            },
            [
              _c("DictionariesInput", {
                attrs: { parameter: "halfday_calc_type" },
                on: { change: _vm.halfDayChange },
                model: {
                  value: _vm.form.halfDayCalcType,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "halfDayCalcType", $$v)
                  },
                  expression: "form.halfDayCalcType",
                },
              }),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.form.halfDayCalcType === "1",
                      expression: "form.halfDayCalcType ==='1'",
                    },
                  ],
                  staticClass: "remind",
                },
                [
                  _c("span", [_vm._v("如班次 9:00-12:00，13:30-18:30")]),
                  _c("br"),
                  _c("span", [_vm._v("上午 = 3小时")]),
                  _c("span", { staticClass: "time-left" }, [
                    _vm._v("下午 = 5小时"),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _vm.form.halfDayCalcType === "2"
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "上午", prop: "halfDayHourAm" } },
                    [
                      _c("CbNumber", {
                        attrs: {
                          "decimal-separator": true,
                          precision: 2,
                          max: "12",
                          suffix: "小时",
                        },
                        model: {
                          value: _vm.form.halfDayHourAm,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "halfDayHourAm", $$v)
                          },
                          expression: "form.halfDayHourAm",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.halfDayCalcType === "2"
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "下午", prop: "halfDayHourPm" } },
                    [
                      _c("CbNumber", {
                        attrs: {
                          "decimal-separator": true,
                          precision: 2,
                          max: "12",
                          suffix: "小时",
                        },
                        model: {
                          value: _vm.form.halfDayHourPm,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "halfDayHourPm", $$v)
                          },
                          expression: "form.halfDayHourPm",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }